import * as Icon from "@iyk/icons"
import * as UI from "@iyk/ui"
import * as ReactDialog from "@radix-ui/react-dialog"
import * as React from "react"

const Dialog = ReactDialog.Root
const DialogClose = ReactDialog.Close
const DialogDescription = ReactDialog.Description
const DialogPortal = ReactDialog.Portal
const DialogTitle = ReactDialog.Title
const DialogTrigger = ReactDialog.Trigger

// #region DialogOverlay

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof ReactDialog.Overlay>,
  React.ComponentPropsWithoutRef<typeof ReactDialog.Overlay>
>(({ className, ...props }, ref) => (
  <ReactDialog.Overlay
    ref={ref}
    className={classForDialogOverlay({ className })}
    {...props}
  />
))

DialogOverlay.displayName = ReactDialog.Overlay.displayName

const classForDialogOverlay = UI.cva([
  // Position
  "fixed inset-0 z-dialog",

  // Appearance
  "bg-black/80 backdrop-blur-[1px]",

  // Animation
  "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
])

// #endregion

// #region DialogContent

const DialogContent = React.forwardRef<
  React.ElementRef<typeof ReactDialog.Content>,
  React.ComponentPropsWithoutRef<typeof ReactDialog.Content> &
    UI.VariantProps<typeof classForDialogContent> & {
      dismissible?: true | false
    }
>(({ className, children, dismissible = true, size, ...props }, ref) => {
  return (
    <>
      <DialogOverlay />
      <ReactDialog.Content
        ref={ref}
        className={classForDialogContent({ className, size })}
        onInteractOutside={(event) => {
          if (!dismissible) {
            event.preventDefault()
          }
        }}
        onEscapeKeyDown={(event) => {
          if (!dismissible) {
            event.preventDefault()
          }
        }}
        onPointerDownOutside={(event) => {
          if (!dismissible) {
            event.preventDefault()
          }
        }}
        {...props}
      >
        {children}
        {dismissible && (
          // TODO: when the dialog content does not contain a focusable element, the close button should not have a focused ring around it
          <ReactDialog.Close className="text-gray-11 p-2 absolute right-1 top-1 rounded-md transition-colors duration-200 hover:bg-gray-3 hover:text-gray-12 disabled:pointer-events-none focus-within:outline focus-within:outline-gray-10">
            <Icon.Cross />
          </ReactDialog.Close>
        )}
      </ReactDialog.Content>
    </>
  )
})

DialogContent.displayName = ReactDialog.Content.displayName

const classForDialogContent = UI.cva(
  [
    // Position
    "fixed inset-x-0 bottom-0 z-dialog",
    "md:bottom-auto md:left-[50%] md:top-[50%] md:translate-x-[-50%] md:translate-y-[-50%]",

    // Appearance
    "rounded-t-md md:rounded-xl bg-background text-gray-12 p-4",

    // Animation
    "data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom",
    "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
    "md:data-[state=closed]:zoom-out-95 md:data-[state=open]:zoom-in-95 md:data-[state=closed]:slide-out-to-left-1/2",
    "md:data-[state=closed]:slide-out-to-top-[48%] md:data-[state=open]:slide-in-from-left-1/2 md:data-[state=open]:slide-in-from-top-[48%]",
  ],
  {
    variants: {
      size: {
        sm: "md:max-w-sm",
        md: "md:max-w-md",
        lg: "md:max-w-lg",
        xl: "md:max-w-5xl",
      },
    },
    defaultVariants: {
      size: "md",
    },
  },
)

// #endregion

export {
  DialogClose as Close,
  DialogContent as Content,
  DialogDescription as Description,
  DialogOverlay as Overlay,
  DialogPortal as Portal,
  Dialog as Root,
  DialogTitle as Title,
  DialogTrigger as Trigger,
}
